.wrapper {
    display: flex;
    padding: 100px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
}

.title{
    color: #141414;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    position: relative;
}

.title::after{
    bottom: -10px;
    width: 45%;
    height: 4px;
    left: 0;
    background: #F67366;
    content: "";
    position: absolute;
}

.span{
    color: #141414;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
}

.img{
    width: 100%;
}

