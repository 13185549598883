.wrapper{
    display: flex;
    padding: 100px 0;
    flex-direction: column;
    position: relative;
}

.titleWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.title{
    align-items: center;
    font-size: 36px;
    font-weight: 700;
    padding-top: 246px;
    position: relative;
}

.span{
    font-size: 36px;
    font-weight: 400;
}

.title::after{
    bottom: -10px;
    width: 130px;
    background: #F67366;
    height: 4px;
    left: 0;
    content: " ";
    position: absolute;
}

.car{
    position: absolute;
    left: 0;
    top: 0;
}

.img{
    position: absolute;
    width: 50%;
    top: 725px;
    left: 50%;
    transform: translateX(-50%);
}

.img1{
    position: absolute;
    width: 50%;
    top: 1100px;
    left: 50%;
    transform: translateX(-50%);
}
