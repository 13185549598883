
.wrapper{
    display: flex;
    padding: 100px 0;
    flex-direction: column;
    gap: 100px;
}

.titleWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title{
    font-size: 36px;
    font-weight: 400;
}

.span{
    font-weight: 700;
    position: relative;
}

.span::after{
    bottom: -10px;
    width: 100%;
    height: 4px;
    left: 0;
    background: #F67366;
    content: "";
    position: absolute;
}