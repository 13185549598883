.section {
    width: 100%;
}

.container {
    max-width: 1140px;
    margin: 0 auto;
}

.secondary{
    background:#F8FAFB;
}

.three{
    background: #EEF1FA;
}

.four{
    background: #2C2D5B;
}