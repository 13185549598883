.wrapper{
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 16px;
    gap: 10px;
    border-radius: 12px;
    border: 1px solid #979797;
    position: relative;
    flex: 1;
}

.wrapper > input {
    all: unset;
    color: #000;
    font-size: 14px;
    font-weight: 400;
}

.wrapper > *{
    z-index: 1;
}

.label{
    position: absolute;
    left: 16px;
    top: 0;
    transform: translateY(-50%);
    padding: 8px;
    background: #fff;
    color:  #979797;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.wrapper > input::placeholder{

    color: #979797
}