.footer{
    display: flex;
    padding: 50px 0;
    flex-direction: column;
    gap: 50px;
}

.wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.title{
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.description{
    color:  #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    min-width:0;
    max-width: 200px;
}

.span{
    color:  #F67366;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.titles{
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.descriptions{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
}

.paragraph{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Media{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.divider{
    height: 1px;
    background: rgba(229, 229, 229, 0.40);
}

.logo p{
    text-align: center;
    color:  #FFF;
    font-size: 20px;
    font-weight: 300;
}

.logo p span:nth-child(1){
    color: #F67366;
    font-size: 20px;
    font-weight: 900;
}

.logo p span:nth-child(2){
    color:  #FFF;
    font-size: 20px;
    font-weight: 900;
}