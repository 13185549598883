.header{
    display: flex;
    height: 815px;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.block{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.title{
    font-size: 40px;
    font-weight: 600;
    color: #141414;
}

.span{
    font-size: 48px;
    font-weight: 700;
    color: #F67366;
}

.paragraph{
    font-size: 14px;
    font-weight: 400;
    color: #333;
}

.button{
    display: flex;
    align-items: center;
    gap: 30px;
    padding-top: 50px;
}

.demo{
    display: flex;
    align-items: center;
    gap: 20px;
}

.circle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 57px;
    height: 57px;
    border-radius: 100px;
    background:  #FFF;
    box-shadow: 0px 0px 25px 0px rgba(151, 151, 151, 0.25);
}

.play{
    font-size: 14px;
    font-weight: 800;
}

.girl{
    margin-right: -150px;
}

.img{
    position: absolute;
    content: "";
    margin-left: -200px;
    margin-bottom: 200px;
    width: 49%;
}