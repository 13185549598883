.navBar{
    display: flex;
    height: 110px;
    justify-content:space-between;
    align-items: center;
}
.links{
    display: flex;
    align-items: center;
    gap: 30px;
    color: #141414;
    font-size: 14px;
    font-weight: 300;
}
.buttons{
    display: flex;
    align-items: center;
    gap: 30px;
}

.link{
    display: flex;
    align-items: center;
}

.title{
    font-size: 24px;
    font-weight: 300;
    color: #2C2D5B
}

.span {
    font-weight: 800;
    color: #F67366;
}

.link:visited {
    color: inherit;
}