.form{
    display: flex;
    padding: 36px;
    justify-content: center;
    align-items: flex-end;
    gap: 22px;
    margin-bottom: 100px;
    border-radius: 30px;
    background:  #FFF;
    box-shadow: 0px 0px 25px 0px rgba(151, 151, 151, 0.20);
}

.forms{
    display: flex;
    align-items: flex-end;
    gap: 22px;
}

.input{
    width: 250px;
    height: 64px;
    border-radius: 12px;
    border: 1px solid #979797;
    background: #FFF;
}
