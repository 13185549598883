.button{
    all: unset;
    display: flex;
    padding: 20px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 800;
    border-radius: 12px;
    background: #2C2D5B;
    color: white
}

.buttonSecondary{
    background: unset;
    color:#2C2D5B;
    border: solid 1px #2C2D5B;
}

.large{
    padding: 28px;
    flex: 1;
}